.shards {
    display: flex;
    flex-wrap: wrap;

}

.shard {
    min-width: 100px;
    order: 5;
    border: solid 1px;
    margin: 4px;
    padding: 4px;
}